.container {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin: 24px;
  position: relative;
}

.newNotificationRedDot {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: var(--accent-danger-80);
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.title {
  font-family: Gilmer-Bold, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
}

.redTitle {
  color: var(--accent-danger-80);
}

.yellowTitle {
  color: var(--accent-warning-60);
}

.description {
  font-size: 14px;
  line-height: 18px;
  color: var(--text-secondary-100);
  margin-top: 8px;
}

.category {
  align-self: flex-end;
  text-align: right;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-tertiary-100);
  margin-top: 8px;
}
