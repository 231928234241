
.iconContainer {
  flex-shrink: 0;
}

.container {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 500px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1.5rem 1rem;
  margin-top: 3rem;
  pointer-events: none;
  z-index: 10000;
}


@media (max-width: 640px) {
  .container {
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 1.5rem;
  }
}

.messageContainer {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
}

.textContainer {
  margin-left: 0.75rem;
  width: 0;
  flex: 1 1;
  padding-top: 0.125rem;
}

.title {
  color: var(--text-secondary-100);
  font-size: 14px;
  font-weight: 600;
}

.descripton {
  color: var(--text-tertiary-100);
  font-size: 12px;
  margin-top: 0.5rem;
}

.closeButtonContainer {
  display: flex;
  margin-left: 1rem;
  flex-shrink: 0;
}

.closeButton {
  border-radius: 0.375rem;
  display: inline-flex;
  color: var(--dark-grey);
}

.closeButton:hover {
  opacity: 0.80;
}

.closeButton:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;

}
