.container {

}

.eye {
  position: relative;
  top: -30px;
  margin-right: 10px;
  cursor: pointer;
  float: right;
}

.eye :hover {
  opacity: 0.8;
}
