.container {
    position: relative;
}

.tooltip-container {
    width: 300px;
    background-color: #ffffff;
    border: 1px solid var( --border-140);
    box-sizing: border-box;
    border-radius: 8px;
    cursor: default;
    position: absolute;
    top: -13px;
    right: -430px;
    overflow: visible;
    padding: 16px;
    z-index: 1002;
    color: var(--text-secondary-100);
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-family: Gilmer-Regular, serif;
}

.bottom {
    top: 30px;
    left: -140px;
    max-width: 300px;
}

.bottom-right {
    top: 30px;
    left: -140px;
    max-width: 300px;
}

.content {
    width: 100%;
    text-align: left;
    white-space: pre-wrap;
}

.bottom-right {
    top: 30px;
    left: -50px;
    max-width: 300px;
}

.arrow-bottom {
    width: 14px;
    height: 14px;
    border: 1px solid var( --border-140);
    position: absolute;
    top: -8px;
    left: 145px;
    border-radius: 2px;
    background-color: #ffffff;
    transform: rotate(45deg);
    border-right: none;
    border-bottom: none;
}

.arrow-bottom-right {
    width: 14px;
    height: 14px;
    border: 1px solid var( --border-140);
    position: absolute;
    top: -8px;
    left: 55px;
    border-radius: 2px;
    background-color: #ffffff;
    transform: rotate(45deg);
    border-right: none;
    border-bottom: none;
}

.icon {
    width: 15px;
    height: 15px;
    margin-left: 4px;
    cursor: pointer;
}