.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 24px;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  row-gap: 24px;
  padding-bottom: 24px;
}

.totalTitle {
  font-size: 20px;
  line-height: 24px;
  font-family: Gilmer-Bold, sans-serif;
  color: var(--text-primary-100);
}

.totalAmount {
  color: var(--primary);
  font-family: Gilmer-Bold, sans-serif;
}

.progress {
  display: flex;
  justify-content: center;
  height: 80%;
  align-content: center;
  align-items: center;
}

.item {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 24px;
  padding: 24px;
  row-gap: 24px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.emoji {
  font-size: 40px;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Gilmer-Bold, sans-serif;
  color: var(--text-primary-100);
  font-size: 20px;
  line-height: 24px;
}

.desc {
  font-size: 16px;
  line-height: 20px;
  font-family: Gilmer-Regular, sans-serif;
  color: var(--text-secondary-100);
}

.save {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Gilmer-Medium, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary);
}

.saveText {
  color: var(--text-primary-100);
  font-size: 16px;
  line-height: 24px;
}

.seeMore {
  font-family: Gilmer-Bold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary);
  text-align: center;
  margin-bottom: 24px;
  cursor: pointer;
}
