.productsContainer {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.productContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  row-gap: 12px;
  margin-top: 12px;
}

.productSeparator {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-top: 28px
}

.loadingIndicator {
  margin-top: 12px;
}

.tag {
  align-self: flex-start;
  background-color: var(--primary-120);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  text-transform: uppercase;
  color: var(--white);
  font-weight: 600;
  font-size: 10px;
  font-family: 'Gilmer-Bold', sans-serif;
}

.tag img {
  height: 24px;
}

.header {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--text-tertiary-100);
  column-gap: 8px;
}

.institutionLogo {
  width: 32px;
}

.productName {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: 'Gilmer-Bold', sans-serif;
  font-size: 14px;
  line-height: 14px;
  color: var(--text-primary-100);
}

.productNameSubText {
  font-size: 12px;
  color: var(--text-secondary-100);
}

.suggestionContent {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.creditCardImageContainer {
  margin: auto;
  flex: 1;
}

.creditCardImage {
  //width: 100px;
  margin: auto;
}

.estimatedBenefits {
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-primary-100);
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 8px;
  flex: 1;
}

.perYear {
  font-size: 12px;
  color: var(--text-secondary-100);
  font-family: unset;
}

.estimatedBenefitsValue {
  font-size: 16px;
  color: var(--primary);
  font-family: 'Gilmer-Bold', sans-serif;
}

.value {
  font-size: 16px;
  color: var(--text-secondary-100);
  font-family: 'Gilmer-Bold', sans-serif;
}

.estimatedBenefitsOffer {
  font-size: 10px;
  line-height: 10px;
  color: var(--primary);
  font-family: 'Gilmer-Regular', sans-serif;
}

.offerContainer {
  border-radius: 6px;
  font-size: 12px;
  padding: 8px;
}

.buttonContainer {
  width: 100%;
  align-self: stretch;
}

.estimatedBenefitsInfo {
  position: absolute;
  font-size: 6px;
  color: #00B8B8;
  border: 1px solid #00B8B8;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  top: 0;
  right: 0;
}

.modalContainer {
  position: relative;
}

.modalWrapper {
  /* my-auto px-3 py-6 bg-white rounded-lg md:w-96 md:mx-auto */
  margin: auto;
  padding: 24px 12px;
  background-color: #FFF;
  border-radius: 12px;
  width: 400px;
  height: 80vh;
  overflow-y: scroll;
}