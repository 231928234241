.container{
  position: relative;
  overflow: visible;
  padding-bottom: 44px;
  padding-top: 12px;
}

.footer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -25px;
  height: 50px;
  color: var(--dark-grey);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: -5px;
  height: 20px;
  color: var(--dark-grey);
  font-size: 12px;
}

.value {
  white-space: nowrap;
}

.title {
  font-size: 12px;
}

.bar {
  width: 80px;
  height: 100px;
}

.current {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: height 0.3s;
  max-height: 500px;
}

.full {
  width: 100%;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  overflow: hidden;
  transition: height 0.3s;
  position: absolute;
  bottom: 55px;
}

.tooltip {
  border-radius: 8px !important;
  width: 240px;
  display: flex;
  flex-direction: column;
}

.tooltipTitle {
  font-family: 'Gilmer-Bold', sans-serif;
}

.base {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: var(--border-100);
  margin-top: 8px;
  margin-bottom: 8px;
}

.bar2 {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: var(--border-100);
}

.footer2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--text-tertiary-100);
  font-size: 12px;
}

.footerValue {
  color: var(--text-secondary-100);
}

.summary {
  background-color: var(--border-120);
  color: var(--text-primary-100);
  font-family: 'Gilmer-Bold', sans-serif;
  text-align: center;
  padding: 4px 8px;
  margin: 8px 0;
}
