.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.periodSelector {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  user-select: none;
  justify-content: flex-end;
  padding-right: 24px;
  margin-top: -28px;
}

.period {
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 100px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  color: var(--primary);
}

.periodSelected {
  background-color: var(--primary);
  color: var(--white);
}

.totalsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  user-select: none;
  padding: 24px;
  align-items: center;
}

.arrowContainer {
}

.total {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-secondary-100);
}

.income {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  line-height: 28px;
  color: var(--primary);
}

.expenses {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  line-height: 28px;
  color: var(--text-secondary-100);
}

.progress {
  display: flex;
  justify-content: center;
  height: 80%;
  align-content: center;
  align-items: center;
}

.reportContainer {
  flex: 1;
}

.tooltip {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 24px;
  padding: 16px;
}

.tooltipItem {
  display: flex;
  flex-direction: row;
  user-select: none;
}

@media (max-width: 640px) {
  .periodSelector {
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 18px;
  }
}
