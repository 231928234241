.container {
  background-color: white;
  display: flex;
  flex-direction: row;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  padding: 0 6px 0 6px;
}

.transaction {
  justify-content: start;
  align-items: center;
  font-family: Gilmer-Medium, sans-serif;
  font-size: 14px;
  color: var(--text-secondary-100);
  display: grid;
  grid-template-columns: 280px 1fr 100px;
  vertical-align: middle;
}

.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
}

.desc {
  font-size: 14px;
  font-family: Gilmer-Regular, serif;
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 6px;
}

.edit {
  width: 40px;
  transition: width 0.3s;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: white;
}

.edit-button-container {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  overflow: hidden;
  margin-top: 30px;
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
}

.edit-button {
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  width: 34px;
  height: 34px;
  padding: 8px;
}

.income {
  color: var(--primary);
}

.splited-icon {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}

.category {
  font-family: Gilmer-Regular, serif;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 640px) {
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 3px;
  }

  .transaction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: Gilmer-Medium, sans-serif;
    font-size: 13px;
    color: var(--text-secondary-100);
  }

  .desc {
    font-size: 11px;
  }

  .category {
    text-align: center;
    font-size: 11px;
    font-family: Gilmer-Regular, serif;
  }

  .splited-icon {
    width: 12px;
    height: 12px;
    margin-right: 3px;
  }

  .container {
    padding: 0px;
  }

  .edit-button-container {
    margin-top: 15px;
  }
}
