.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 65px;
  justify-content: space-between;
  font-size: 16px;
  color: var(--text-secondary-100);
  font-family: Gilmer-Medium, serif;
  position: relative;
}

.divider {
  position: absolute;
  width: 100%;
  background-color: var(--border-120);
  height: 1px;
  bottom: 0px;
}

.input-container {
  color: black;
  display: flex;
  flex-direction: row;
  min-height: 30px;
  align-items: center;
}

.with-input-underline {
  border-bottom: 1px solid var(--border-120);
}

.input {
  background-color: transparent;
  display: block;
  outline: none;
  font-size: 16px;
  border-width: 0px;
  text-align: right;
  margin-right: 0;
  padding-right: 4px;
  width: 100%;
}

.disabled {
  color: var(--text-secondary-100);
  text-align: right;
}

.input:focus {
  outline: none;
  box-shadow: unset;
  border-width: 0;
}

.left {
  display: flex;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.button {
  cursor: pointer;
  color: var(--primary);
}

.tooltip {
  width: 15px;
  height: 15px;
  margin-left: 4px;
  cursor: pointer;
}

.labelWrapper {
  width: 93%;
}

@media (max-width: 640px) {
  .left {
    white-space: normal;
  }
}