.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.date {
    font-size: 12px;
    color: var(--text-secondary-100);
    margin-top: 8px;
    font-family: Gilmer-Medium, serif;
}