
.container {
  margin: 60px 20px 40px 20px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.item {
  margin: 2px;
  margin-top: 0;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.label {
  font-size: 14px;
  line-height: 18px;
  height: 54px;
  text-align: center;
  color: var(--text-secondary-100);
  overflow: hidden;
  padding: 0 2px 0 2px;
}

.emoji {
  font-size: 30px;
}

.emoji-container {
  height: 65px;
  width: 65px;
  border-radius: 100px;
  background-color: var(--white);
  margin-bottom: 8px;
  box-shadow: 0px 8px 20px rgba(193, 193, 193, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.selected-container {
  border: 2px solid var(--primary);
}

.disabled-container {
  border: 2px solid var(--grey-blue);
}

.button-container {
  width: 100%;
  height: 90px;
  padding: 10px 15px 20px;
  background-color: white;
  position: fixed;
  flex-direction: column;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-secondary-100);
  font-size: 14px;
  font-family: Gilmer-Medium, serif;
}

.button {
  width: 60%;
}

.selected-label {
  color: var(--primary);
}

.count {
  background-color: var(--primary);
  height: 16px;
  width: 16px;
  border-radius: 8px;
  font-size: 13px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}
