.box {
  width: 100%;
  min-height: 88px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 24px;
  background-color: white;
  border-style: dashed;
  border-width: 1px;
  border-color: white;
}

.excluded {
  border-color: var(--dark-grey);
  box-shadow: 0px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  font-size: 18px;
  font-family: Gilmer-Bold, sans-serif;
}

.header-title::first-letter {
  text-transform: capitalize;
}

.tooltip {
  height: 17px;
  width: 17px;
  cursor: pointer;
}

.with-click {
  cursor: pointer;
}

@media (max-width: 820px) {
  .box {
    padding: 16px;
    min-height: 50px;
  }
}

@media (max-width: 640px) {
  .box {
    padding: 12px;
    margin-bottom: 14px;
  }

  .header-title {
     font-size: 16px;
  }
}
