.main {
  display: flex;
  flex-direction: row;
}

.section {
  min-height: 134px;
  width: 50%;
}

.left {
  padding-right: 40px;
  width: 360px;
}

.right {
  flex: 1;
}

.income-expense-chart {
  display: flex;
  width: 75%;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px auto;
  align-items: flex-end;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  font-size: 20px;
  font-family: Gilmer-Medium, sans-serif;
  text-transform: capitalize;
}

.header-button {
  height: 36px;
  width: 36px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 18px;
  box-shadow: 0px 8px 20px rgba(193, 193, 193, 0.2);
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  flex-shrink: 0;
  justify-content: center;
}

.transaction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Gilmer-Medium, sans-serif;
  font-size: 16px;
  color: var(--text-secondary-100)
}

.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
}

.desc {
  font-size: 14px;
  font-family: Gilmer-Regular, serif;
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 6px;
}

.date {
  font-size: 14px;
  font-family: Gilmer-Regular, serif;
  color: var(--text-secondary-100);
  margin-bottom: 16px;
  margin-left: 8px;
}

.progress {
  display: flex;
  justify-content: center;
  height: 80%;
  align-content: center;
  align-items: center;
}

.edit-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.button-container {
  display: flex;
  flex-direction: row;
  overflow: visible;
  align-items: center;
}

.filter-count {
  font-size: 12px;
  position: absolute;
  top: 0;
  left: -5px;
  height: 20px;
  width: 20px;
  background-color: var(--accent-warning-100);
  border-radius: 10px;
  text-align: center;
  color: white;
}

.date-button {
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  padding: 5px 12px;
  min-width: 100px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 18px;
  box-shadow: 0px 8px 20px rgba(193, 193, 193, 0.2);
  font-size: 16px;
  font-family: Gilmer-Bold, serif;
  color: var(--primary);
}

.button-icon {
  height: 20px;
  width: 20px;
}

.small-icon {
  height: 14px;
  width: 14px;
}

.date-text {
  font-size: 14px;
  margin-left: 10px;
}

@media (max-width: 820px) {
  .section {
    width:  100%;
  }

  .header {
    margin-bottom: 20px;
  }

  .left {
    padding-right: 0;
    width:  100%;
  }

  .top-two {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
}

@media (max-width: 640px) {
  .top-two {
    display: block;
  }
  .header {
    font-size: 18px;
  }
  .date-button {
    min-height: 36px;
    height: unset;
  }
}