.button {
    transition: all .3s;
    cursor: pointer;
    padding: 2px;
}

.open {
    transform: rotate(180deg);
}

.path {
    transition: all .3s;
}