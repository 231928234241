
.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60px;
}

.containerM {
  width: 100%;
  height: 100%;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--text-primary-100);
}

.mxWidgetIframe {
  flex-grow: 1;
  background-color: var(--background);
}

.waiting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
}

.progressContainer {
  width: 165px;
}

.progress {
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.piggyText {
  font-weight: 700;
  font-family: Gilmer-Bold, sans-serif;
  padding: 4px 8px;
  margin-top: 8px;
  width: 55px;
  font-size: 12px;
  color: var(--primary-100);
  background-color: var(--white);
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 2.60px;
}

.piggy {
  margin-top: 10px;
  width: 120px;
}

.bePatient {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--text-primary-100);
  margin-top: 30px;
}

.bePatientSubText {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--text-secondary-100);
  margin-top: 8px;
}

.quotesContainer {
  height: 160px;
}

.quoteContainer {
  width: 100%;
  padding: 40px;
  white-space: pre-line;
}

.quote {
  background-color: white;
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
}

.quoteText {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--text-primary-100);
}

.quoteAuthor {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--text-secondary-100);
}
