
.appleButton {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  color: var(--white);
  background-color: black !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  border-width: 1px;
}

.appleButton:hover {
  opacity: 0.80;
}

.appleButtonText {
  color: var(--white);
}
