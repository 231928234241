.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}