.date {
  margin: 0 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-secondary-100);
}

.createBudgetItem {
  border-radius: 24px;
  display: flex;
  padding: 16px;
  margin: 24px;
  position: relative;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
}

.createBudgetEmoji {
  font-size: 28px;
  margin-right: 8px;
}

.createBudgetTitle {
  font-family: Gilmer-Bold, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
}

.createBudgetDesc {
  font-size: 14px;
  line-height: 18px;
  color: var(--text-secondary-100);
  margin-top: 8px;
}

.createBudgetTitle {
  font-family: Gilmer-Bold, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
}
