.container {
  margin: 60px 40px 40px 40px;
  padding-bottom: 20px;
  color: var(--text-primary-100);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emoji {
  font-size: 50px;
}

.name {
  font-size: 20px;
  font-family: Gilmer-Medium, serif;
  padding-bottom: 5px;
}

.desc {
  font-size: 16px;
  color: var(--text-secondary-100);
  padding-bottom: 5px;
}

.amount {
  color: var(--text-primary-100);
  font-family: Gilmer-Medium, serif;
}

.content {
  margin: 20px 0 20px;
}
