.container {
  overflow: scroll;
  height: 100%;
}

.content {
  margin: 60px 40px 0px 40px;
  color: var(--text-primary-100);
  display: flex;
  flex-direction: column;
  cursor: default;
}

.form {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 20px;
  color: var(--text-primary-100);
  font-family: Gilmer-Medium, serif;
}

.title-empty {
  font-size: 20px;
  color: var(--text-secondary-100);
  font-family: Gilmer-Medium, serif;
}

.amount {
  margin: 5px 0px 20px;
  font-size: 16px;
  color: var(--text-secondary-100);
  font-family: Gilmer-Medium, serif;
}

.header {
  display: flex;
  margin: 20px 20px 14px 20px;
  justify-content: space-between;
}

.header-title {
  font-size: 20px;
  font-family: 'Gilmer-Bold';
}

.button-group {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 20px;
}

.button {
  width: 100%;
  width: 300px;
  margin-top: 20px;
}

.float-button {
  position: absolute;
  bottom: 30px;
  width: 300px;
  align-self: center;
}

.calendar {
  padding: 5px 7px 0px;
  overflow: hidden;
  transition: all 0.3s;
}

.negative-amount {
  color: var(--accent-danger-100);
}

.positive-amount {
  color: var(--primary);
}

@media (max-width: 640px) {
  .content {
    margin: 30px 20px 0px 20px;
  }
}
