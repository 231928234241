.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image {
  width: 50%;
  padding: 10px;
}

.content {
  width: 50%;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 24px;
  font-family: 'Gilmer-Bold', sans-serif;
  margin-bottom: 15px;
  color: var(--text-primary-100);
  max-width: 520px;
}

.desc {
  font-size: 18px;
  color: var(--text-secondary-100);
  margin-bottom: 15px;
}

.button {
  min-width: 200px;
}

@media (max-width: 640px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .image {
    width: 70%;
  }

  .content {
    width: 90%;
  }
}
