.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 290px;
  margin-top: 16px;
}

.emoji {
  font-size: 70px;
  align-self: center;
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-family: 'Gilmer-Bold';
  text-align: center;
  padding: 0 5px 0 5px;
}

.sub-title {
  padding: 0 5px 0 5px;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}
