.menuBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.menuBarMobile {
  display: none;
}

.icons {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
  flex-direction: row;
  margin-top: 0;
}

.roundIcon {
  width: 36px;
  height: 36px;
  background-color: var(--white);
  border-radius: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.roundIconImgDiv {
  position: relative;
}

.roundIconImgDiv :hover {
  opacity: 0.80;
}

.newNotificationRedDot {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--accent-danger-80);
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

@media (max-width: 864px) {
  .menuBar {
    display: none;
  }

  .menuBarMobile {
    display: block;
  }
}
