
.fbButton {
  width: 100%;
  margin-top: 12px;
  padding: 0.5rem 1rem;
  justify-content: center;
  color: white;
  background-color: var(--facebook);
}

.fbButton:hover {
  opacity: 0.80;
}
