
.currentBalance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
  padding-top: 24px;
  height: 100%;
}

.balance {
  font-family: Gilmer-Medium, sans-serif;
  color: var(--primary);
}

.savingsCapacity {
  color: var(--text-tertiary-100);
  font-size: 14px;
  line-height: 20px;
}

.progress {
  display: flex;
  justify-content: center;
  height: 80%;
  align-content: center;
  align-items: center;
}
