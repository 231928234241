.container {
  margin: 60px 35px 40px 35px;
  padding-bottom: 20px;
  color: var(--text-primary-100);
  display: flex;
  flex-direction: column;
  cursor: default;
}

.selections {
  width: 300px;
  margin: 0 auto;
  margin-top: 100px;
}

.select-button {
  width: 100%;
  margin-top: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 78px;
  height: 78px;
  border-radius: 4px;
}

.name {
  font-size: 20px;
  font-family: Gilmer-Medium, serif;
  padding-bottom: 5px;
}

.desc {
  font-size: 16px;
  color: var(--text-secondary-100);
  padding-bottom: 5px;
}

.amount {
  color: var(--text-primary-100);
  font-family: Gilmer-Medium, serif;
}

.content {
  margin: 20px 0 20px;
  width: 100%;
}

.category-button {
  background-color: var(--border-100);
  border-radius: 22px;
  padding: 9px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.category-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.category {
  color: var(--text-secondary-100);
  font-size: 14px;
  font-family: Gilmer-Medium, serif;
  padding-right: 2px;
  padding-left: 2px;
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 80%;
}

.remove-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.open-icon {
  width: 20px;
  height: 20px;
}

.summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--text-secondary-100);
  font-size: 16px;
  margin-bottom: 24px;
}

.budget-total {
  font-size: 16px;
  font-family: Gilmer-Bold, serif;
}

.perfect {
  color: var(--success);
}

.exceed {
  color: var(--accent-danger-80);
}

.summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-button-container {
  display: flex;
  width: 100%;
  margin: 16px 0;
  align-items: center;
  color: var(--primary);
  font-family: Gilmer-Bold, serif;
  font-size: 16px;
}

.add-button {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.button-group {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-self: center;
  height: 90px;
  justify-content: space-between;
}

.calendar {
  padding: 5px 7px 0px;
  overflow: hidden;
  transition: all 0.3s;
}

.float-button-container {
  display: flex;
  justify-content: center;
}

.float-button {
  width: 300px;
  margin: 0 auto;
}

@media (max-width: 640px) {
  .container {
    margin: 30px 20px 40px 20px;
  }
}

.splited-icon {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}