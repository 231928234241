
.main-title {
  justify-self: center;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-primary-100);
  font-family: 'Gilmer-Bold';
  align-self: center;
}

@media (max-width: 640px) {
  .main-title {
    font-size: 16px;
    line-height: 20px;
  }
}

.image {
  user-select: none;
}

@media (max-width: 640px) {
  .image {
    width: 256px;
    margin: auto;
    margin-bottom: 24px;
    margin-top: 12px;
  }
}

.title {
  text-align: center;
  margin-bottom: 24px;
}

@media (max-width: 640px) {
  .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

.text {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--dark-grey);
  margin-bottom: 24px;
}

@media (max-width: 640px) {
  .text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }
}

.link {
  color: var(--primary);
  text-decoration: underline;
}

.button {
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 240px;
}


.confirm-button {
  composes: button;
  width: 260px;
}


.problem {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 24px;
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
  color: var(--text-secondary-100);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}

.arrow {
  //display: flex;
  //justify-content: center;
  //align-items: center;
}

.arrow-img {
  height: 24px;
  min-width: 24px;
  display: block;
  margin: 0 auto;
}

@media (max-width: 640px) {
  .problem {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }
}

.textarea {
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 10px;
  border-width: 0px !important;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px auto 0 auto;
  color: var(--text-primary-100);
  height: 5em;
  width: 30em;
  display: block;
}

@media (max-width: 1024px) {
  .textarea {
    width: 20em;
  }
}

@media (max-width: 375px) {
  .textarea {
    width: 15em;
  }
}

.textarea:focus {
  outline: none !important;
  --tw-ring-color: primary !important;
  --tw-ring-opacity: 1 !important;
  border-color: transparent !important;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.error {
  color: var(--accent-danger-80);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin: 4px 0 24px 0;
}

.sad-face {
  composes: image;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  width: 56px;
}

.comment {
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 10px;
  border-width: 0px !important;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
}

.danger-button {
  composes: button;
  margin-top: 32px;
}
