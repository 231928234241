.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 24px;
}

.progress {
  display: flex;
  justify-content: center;
  height: 80%;
  align-content: center;
  align-items: center;
}

.total {
  align-self: start;
}

.recurringItem {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  width: 100%;
}

.recurringMerchant {
  width: 40px;
  height: 40px;
}

.middle {
  flex: 1;
  flex-direction: column;
  font-family: Gilmer-Bold, sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-secondary-100);
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.exp {

}

.desc {
  font-size: 12px;
  line-height: 18px;
  font-family: Gilmer-Regular, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: var(--text-tertiary-100);
}

.end {
  flex-direction: column;
  font-family: Gilmer-Regular, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-tertiary-100);
  text-align: right;
}

.seeMore {
  font-family: Gilmer-Bold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary);
  text-align: center;
  justify-self: end;
  margin-bottom: 24px;
  cursor: pointer;
}
