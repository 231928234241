
.nav-div {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.dot {
  user-select: none;
  color: var(--border-140);
  cursor: pointer;
  font-size: 36px;
  text-shadow: none;
  transition: color .2s ease;
  will-change: color;
  display: inline-block;
  vertical-align: middle;
  margin: 3px 2px 0;
}

.active {
  color: var(--primary);
  font-size: 48px;
  margin-top: 0;
}
