.container {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--text-primary-100);
  margin-bottom: 24px;
}

.subTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 24px;
  color: var(--text-secondary-100);
}

.waiting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
}

.progressContainer {
  width: 265px;
}

.progress {
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: auto auto 24px;
}

.buttonGrant {
  display: flex;
  align-items: center;
  justify-content: center;
}

.piggyText {
  font-weight: 700;
  font-family: Gilmer-Bold, sans-serif;
  padding: 4px 8px;
  margin-top: 8px;
  width: 55px;
  font-size: 12px;
  color: var(--primary-100);
  background-color: var(--white);
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 2.60px;
}

.piggy {
  margin-top: 10px;
  width: 180px;
}
