
.background {
  background-color: rgba(28,28,28,0.4);
  transition: background-color 300ms linear;
}

.content {
  width: 100vw;
}

.header {
  display: flex;
  margin: 20px 20px 14px 20px;
  justify-content: space-between;
  height: 30px;
}

.header-title {
  font-size: 18px;
  font-family: 'Gilmer-Bold';
  text-align: center;
}

.float-button {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 0;
  right: 0;
  min-width: 200px;
  max-width: 260px;
  margin: 0 auto;
}