.container {
  background-color: white;
  display: flex;
  flex-direction: row;
  transition-timing-function: ease-in-out;
  width: 100%;
}

.edit {
  width: 40px;
  transition: width 0.3s;
  display: flex;
  align-content: center;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: row;
}

.image {
  font-size: 28px;
  width: 38px;
}

.expand {
  width: 40px;
  height: 20px;
}

.open {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.title {
  color: var(--dark-grey);
  font-size: 16px;
  font-family: Gilmer-Medium, serif;
}

.sub-title {
  color: var(--text-tertiary-100);
  font-size: 12px;
}

.bar {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: var(--border-100);
}

.base {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: var(--border-100);
  margin-top: 8px;
  margin-bottom: 8px;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--text-tertiary-100);
  font-size: 14px;
}

.footer-value {
  color: var(--text-secondary-100);
}

.edit-button-container {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  overflow: hidden;
  background-color: white;
  margin-top: 50px;
  cursor: pointer;
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
}

.edit-button {
  background-color: white;
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  width: 34px;
  height: 34px;
  padding: 8px;
}

.edit-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
}

.progress {
  margin: 0 auto;
  padding-top: 40%;
}

.footer-value {
  color: var(--text-secondary-100);
}

@media (max-width: 640px) {
  .title {
    font-size: 14px;
  }
  .image {
    font-size: 22px;
    width: 28px;
  }
  .edit-button-container {
    margin-top: 35px;
  }
  .edit {
    display: block;
  }
}
