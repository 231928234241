.container {
  margin: 60px 20px 40px 20px;
  padding-bottom: 20px;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: 15px;
}

.header {
  display: flex;
  align-items: center;
}

.emoji {
  margin-right: 8px;
  font-size: 28px;
}

.title {
  font-size: 16px;
  color: var(--text-secondary-100);
  font-family: Gilmer-Medium, serif;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.sub-title {
  font-size: 12px;
  color: var(--text-secondary-100);
}
