.date {
    font-size: 14px;
    font-family: Gilmer-Regular, serif;
    color: var(--text-secondary-100);
    margin-bottom: 16px;
    margin-left: 8px;
}

.container {
    margin-top: 1rem;
}

@media (max-width: 820px) {
    .container {
        margin-top: 0px;
    }
  }