.container {
    margin-top: 20px;
    cursor: default;
    padding-bottom: 20px;
}

.small {
    margin-top: 60px;
}

.header {
    margin: 0 50px 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    min-height: 36px;
    cursor: pointer;
}

.custom-button {
    display: flex;
    cursor: pointer;
}

.last {
    display: flex;
    margin-bottom: 30px;
    cursor: default;
}

.date-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 36px;
    padding: 5px 10px;
    border-radius: 18px;
    box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
    font-size: 13px;
    font-family: Gilmer-Regular, serif;
    color: var(--text-secondary-100);
  }
  
.button-icon {
    height: 14px;
    width: 14px;
    align-self: center;
}

.text {
    font-family: Gilmer-bold, serif;
}

.title {
    font-size: 16px;
    font-family: Gilmer-bold, serif;
    color: var(--text-secondary-100);
    margin-right: 10px;
}

.button-container {
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.button {
    width: 70%;
    align-self: center;
}

.date-text {
    margin-left: 8px;
}

@media (max-width: 640px) {
    .header {
        margin-left: 16px;
        width: 100%;
    }
}