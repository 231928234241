.container {
    position: relative;
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    font-family: Gilmer-Regular, sans-serif;
    width: 80%;
}

.search {
    display: flex;
    flex-direction: row;
    flex: 1 1 0%;
    align-items: center;
}

.input {
    padding: 2px;
    font-size: 16px;
    outline: none;
    background-color: transparent;
    display: block;
    outline: none;
    font-size: 16px;
    border-width: 0px;
    margin-right: 0;
    width: 100%;
    color: var(--text-primary-100);
}

.input:focus {
    outline: none;
    box-shadow: unset;
    border-width: 0;
}

.input::placeholder {
    color: var(--text-tertiary-100);
}

.icon {
    height: 26px;
    width: 26px;
    margin-right: 7px;
}