.container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.arrow {
    width: 22px;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px;
}

.header {
    padding-right: 24px;
    padding-left: 24px;
}

.loading {
  height: 220px;
  display: flex;
  align-items: center;
}

.view-more-button {
    color: var(--primary);
    height: 36px;
    justify-content: center;
    cursor: pointer;
    font-family: 'Gilmer-Bold', sans-serif;
    font-size: 16px;
    line-height: 24px;
}

@media (max-width: 820px) {
    .header {
        padding-right: 16px;
        padding-left: 16px;
    }
  }

  @media (max-width: 640px) {
    .header {
        padding-right: 12px;
        padding-left: 12px;
    }
  }
