.container {
    display: flex;
    flex-direction: row;
    color: var(--text-primary-100);
    justify-content: space-between;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.categories-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
}

.title {
    font-size: 20px;
    font-family: 'Gilmer-Medium';
    margin-bottom: 8px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 48px;    
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.excerpt {
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 54px;    
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.image {
    width: 204px;
    min-width: 204px;
    height: 144px;
    border-radius: 12px;
    margin-left: 8px;
    background-color: var(--surface-140);
    object-fit: cover;
}

.category {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.category-text {
    font-size: 12px;
    color: var(--text-secondary-100);
}

@media (max-width: 640px) {
    .image {
        width: initial;
        min-width: initial;
        max-height: 100px;
        max-width: 100px;
    }

    .excerpt {
        display: none;
    }

    .title {
        font-size: 14px;
        max-height: 66px;    
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    
}