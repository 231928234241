.container {
    display: flex;
    padding: 15px;
}

.card {
    max-width: 300px;
    height: 300px;
    border-radius: 24px;
    margin: 0 auto;
    overflow: hidden;
    white-space: pre-wrap;
    background-color: var(--white);
    box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  }
  
.card-content {
    padding: 20px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 200px;
    color: var(--text-primary-100);
    font-size: 14px;
    font-family: 'Gilmer-Regular';
}

.card-image {
    width: 100%;
    object-fit: cover;
    min-height: 100px;
    background-color: var(--surface-140);
    margin-bottom: 0px;
}

.card-logo {
    height: 40px;
    object-fit: contain;
    margin-bottom: 0;
    max-width: 150px;
}

.card-link {
    all: initial;
}

.card-link:hover {
    text-decoration: none;
}

.card-footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.card-desc {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    line-height: 18px;
    font-size: 14px;
    color: var(--text-primary-100);
    font-family: 'Gilmer-Regular';
}

.date {
    font-size: 12px;
    color: var(--text-tertiary-100);
}

.card-button {
    all: initial;
    width: 130px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'Gilmer-Medium';
    font-size: 14px;
}