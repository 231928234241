
.container {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
}

.loadingContainer {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.manageAccounts {
  font-family: Gilmer-Bold, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary);
  align-self: flex-end;
}

.all {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 26px 0 20px 0;
  border-bottom-width: 1px;
}

.heading7 {
  font-family: Gilmer-Bold, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-primary-100);
}

.button {
  margin-top: 48px;
  margin-bottom: 48px;
  align-self: center;
}

.institutionContainer {
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom-width: 1px;
}

.institutionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.institutionName {
  flex: 1;
}

.institutionImg {
  height: 42px;
}

.institutionStatus {
  color: var(--text-tertiary-100);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.account {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.accountNameContainer {
  flex: 1;
}

.accountName {
  font-family: Gilmer-Bold, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-secondary-100);
}

.accountBalance {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-tertiary-100);
}

.negativeBalance {
  color: var(--accent-danger-80);
}

.positiveBalance {
  color: var(--success);
}
