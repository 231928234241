
.welcomePopup {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
}

.content {
  min-width: 300px;
  max-width: 500px;
  margin: 15% auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background-color: var(--white);
}

.header {
  margin-left: auto;
  margin-right: 0;
}

.closeButton {
  border-radius: 0.375rem;
  color: var(--grey-blue);
  outline: none;
}

.closeButton:hover {
  opacity: 0.80;
}

.body {
  text-align: center;
  padding: 0 20px;
  margin: auto;
}

.title {
  font-family: 'Gilmer-Medium';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  margin: auto auto 10px;
  color: var(--text-primary-100);
}

.description {
  font-family: 'Gilmer-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  margin: auto auto 10px;
  color: var(--text-secondary-100);
}

.buttons {
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
}

.button {
  min-width: 200px;
}

.emoji {
  font-size: 34px;
  margin-bottom: 10px;
}


@media (max-width: 640px) {
  .content {
    max-width: 320px;
  }
  .title {
    font-size: 18px;
  }
  
  .description {
    font-size: 14px;
  }
}