.container {
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: calc(0.5rem);
  margin-bottom: 0;
}

.exceedMessage {
  font-size: 12px;
  color: #cd2828;
  margin: 7px 0;
}

.backDiv {
  width: 100%;
  display: flex;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
}

.backImg {
  height: 1rem;
  width: 1.5rem;
  position: absolute;
  cursor: pointer;
  object-fit: contain;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: visible;
  padding-bottom: 20px;
}

.instImage {
  width: 7rem;
  height: 7rem;
  object-fit: contain;
  margin-top: 20px;
}

.instName {
  color: var(--text-primary-100);
  margin-top: 0.25rem;
  font-size: 20px;
  font-family: Gilmer-Bold, sans-serif;
}

.syncStatus {
  text-align: center;
  color: var(--text-primary-100);
}

.success {
  color: var(--success);
}

.error {
  color: var(--accent-danger-100);
}

.syncDate {
  color: var(--text-tertiary-100);
  margin-top: 0.25rem;
}

.actionBtn {
  margin-top: 0.25rem;
  width: 12rem;
}

.accountItemContainer {
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.accountNameContainer {
  display: flex;
  flex-direction: column;
}

.accountName {
  font-family: Gilmer-Bold, sans-serif;
  color: var(--text-primary-100);
  font-size: 14px;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.accountType {
  font-size: 12px;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-secondary-100);
}

.accountBalanceContainer {
  font-family: Gilmer-Bold, sans-serif;
  text-align: left;
}

.list {
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 640px) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .accountItemContainer {
    flex-direction: column;
    align-items: unset;
  }

  .accountBalanceContainer {
    text-align: right;
  }
}