.container {
    background-color: var(--white);
    color: var(--primary);
    display: inline-block;
    margin-right: 0.4rem;
    padding: 0.3rem 1rem;
    margin-bottom: 0.4rem;
    border-radius: 50px;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
}

.scrollbar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: scroll;
}

.scrollbar::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.selected {
    background-color: var(--primary);
    color: var(--white);
}

.header {
    font-size: 20px;
    font-family: Gilmer-Medium, sans-serif;
    text-transform: capitalize;
    margin-bottom: 1rem;
}