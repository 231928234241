.container {
  margin: 0px 30px 40px 30px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ctg-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  height: 27px;
}

.sub-ctg-container{
  padding-left: 34px;
}

.ctg-container-left {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: var(--text-secondary-100);
  font-family: Gilmer-Medium, sans-serif;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.emoji {
  font-size: 18px;
  margin-right: 10px;
}

.uncheck {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.button {
  width: 70%;
}

.button-container {
  width: 100%;
  height: 90px;
  padding: 10px 15px 20px;
  position: fixed;
  flex-direction: column;
  bottom: 0;
  display: flex;
  align-items: center;
}

.title-container {
  width: 100%;
  margin: 24px 0;
  margin-top: 10px;
  font-size: 20px;
  font-family: Gilmer-Bold, sans-serif;
  color: var(--text-primary-100);
}

.count {
  font-size: 12px;
  height: 20px;
  width: 20px;
  background-color: var(--accent-warning-100);
  border-radius: 10px;
  text-align: center;
  color: white;
  margin-left: 10px;
}

.subs-container {
  overflow: hidden;
  transition: height 0.3s;
}

.search {
  align-self: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .title-container {
    font-size: 18px;
  }
}