.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 24px;
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
  border-radius: 24px;
  margin-bottom: 24px;
  row-gap: 12px;
}

.image {
  width: 116px;
}

.text1 {
  font-family: Gilmer-Medium, sans-serif;
  color: var(--text-secondary-100);
  font-size: 20px;
  line-height: 24px;
  margin: 16px 0px;
}

.text2 {
  color: var(--text-secondary-100);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: center;
}
