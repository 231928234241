.main {
  display: flex;
  flex-direction: row;
}

.section {
  min-height: 134px;
  width: 50%;
}

.left {
  padding-right: 40px;
  width: 360px;
}

.right {
  flex: 1;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  font-size: 20px;
  font-family: Gilmer-Medium, sans-serif;
  text-transform: capitalize;
}

@media (max-width: 820px) {
  .section {
    width:  100%;
  }

  .header {
    margin-bottom: 20px;
  }

  .left {
    padding-right: 0;
    width:  100%;
  }

  .top-two {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
}

@media (max-width: 640px) {
  .top-two {
    display: block;
  }
}