.grid {
  display: grid;
  column-gap: 32px;
  row-gap: 8px;
}

.leftContainers {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
  min-width: 350px;
}

.averagesContainer {
  height: 220px;
}

.tipsContainer {
  height: 305px;
}

.chartContainer {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 550px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.chartContainerHeader {
  padding-left: 24px;
  padding-right: 24px;
}

.frequentContainer {
  min-width: 350px;
  height: 350px;
}


@media (max-width: 1024px) {
  .leftContainers {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    flex-direction: row;
    min-width: unset;
    column-gap: 32px;
  }
  .chartContainer {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    width: 100%;
  }
  .frequentContainer {
    min-width: unset;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .leftContainers {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    flex-direction: column;
    min-width: unset;
  }
  .chartContainer {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .frequentContainer {
    min-width: unset;
  }
}
