
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.selectorContainer {
  cursor: pointer;
  position: relative;
  z-index: 0;
  display: inline-flex;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  font-size: 16px;
  box-shadow: 0px -1px 10px rgba(172, 194, 215, 0.12), 0px 0px 6px rgba(172, 194, 215, 0.05), 0px 4px 10px rgba(172, 194, 215, 0.12);
}

.button {
  width: 50%;
  border-radius: 20px;
  background-color: var(--white);
  color: var(--primary);
}

.button-selected {
  background-color: var(--primary);
  color: var(--white);
}

.itemList {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 2rem;
}

.category {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 6rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.categoryBackground {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  width: 70px;
  box-shadow: 0px 8px 20px rgba(193, 193, 193, 0.2);
  border-radius: 60px;
}

.categoryImg {
  font-size: 2.25rem;
  line-height: 2.5rem;
  padding: 1rem;
  align-self: center;
}

.categoryLabel {
  font-size: 12px;
  color: var(--text-secondary-100);
  align-self: center;
  margin-top: 10px;
}

@media (max-width: 640px) {
  .itemList {
    margin-top: 1rem;
  }

  .categoryImg {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
